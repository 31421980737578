import { config } from '../config/env';

export interface PaymentResponse {
  success: boolean;
  params: {
    iframe_token: string;
    [key: string]: string | number;
  };
}

export async function createPayment(amount: number, credits: number): Promise<PaymentResponse> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication required');
    }

    if (!amount || amount <= 0) {
      throw new Error('Invalid payment amount');
    }

    if (!credits || credits <= 0) {
      throw new Error('Invalid credits amount');
    }

    const response = await fetch(`${config.API_URL}/api/payment/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        amount: Math.round(amount), // Ensure whole number for TRY
        credits: Math.round(credits)
      })
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || data.message || 'Payment creation failed');
    }

    if (!data.success || !data.params?.iframe_token) {
      throw new Error('Invalid payment response from server');
    }

    return data;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Failed to process payment';
    console.error('Payment creation error:', { message: errorMessage, error });
    throw new Error(errorMessage);
  }
}