import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MessageSquare,
  ImagePlus,
  CreditCard,
  Sparkles,
  Coins,
  Menu,
  Plus,
  QrCode,
  FileText,
} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import { useLanguage } from "../context/LanguageContext";
import { toast } from "react-hot-toast";
import { config } from "../config/env";
import ChatWithLlama from "../components/ChatWithLlama";
import ImageGenerator from "../components/ImageGenerator";
import QRCodeGenerator from "../components/QRCodeGenerator";
import SubscriptionPlans from "../components/SubscriptionPlans";
import PaymentFrame from "../components/PaymentFrame";
import CreditsPurchase from "../components/CreditsPurchase";
import SVGCreator from "../components/SVGCreator";

interface Subscription {
  status: "none" | "basic" | "premium" | "enterprise";
  endDate: string | null;
  active: boolean;
  plan: {
    credits: number;
    price: number;
    duration: number;
  } | null;
}

interface UserCredits {
  credits: number;
}

type ActiveTab = "chat" | "image" | "qr" | "svg";

function Dashboard() {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [credits, setCredits] = useState<UserCredits | null>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showCreditsPurchaseModal, setShowCreditsPurchaseModal] =
    useState(false);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<ActiveTab>("chat");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const { t } = useLanguage();
  const navigate = useNavigate();

  const isPremium =
    subscription?.status === "premium" || subscription?.status === "enterprise";

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const [subscriptionRes, creditsRes] = await Promise.all([
        fetch(`${config.API_URL}/api/subscription`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
        fetch(`${config.API_URL}/api/credits`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      ]);

      if (!subscriptionRes.ok || !creditsRes.ok) {
        throw new Error("Failed to fetch user data");
      }

      const [subscriptionData, creditsData] = await Promise.all([
        subscriptionRes.json(),
        creditsRes.json(),
      ]);

      setSubscription(subscriptionData);
      setCredits(creditsData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (plan: any) => {
    try {
      const response = await fetch(`${config.API_URL}/api/payment/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          type: "subscription",
          planType: plan.type,
          amount: plan.price,
        }),
      });

      if (!response.ok) throw new Error("Failed to create subscription");

      const data = await response.json();
      if (data.success && data.params?.iframe_token) {
        setPaymentToken(data.params.iframe_token);
        setShowSubscriptionModal(false);
      } else {
        throw new Error("Invalid payment response");
      }
    } catch (error) {
      console.error("Subscription error:", error);
      toast.error("Failed to process subscription");
    }
  };

  const handlePaymentMessage = (status: "success" | "failed") => {
    if (status === "success") {
      toast.success("Payment successful!");
      fetchUserData();
    } else {
      toast.error("Payment failed. Please try again.");
    }
    setPaymentToken(null);
  };

  const sidebarItems = [
    {
      id: "chat",
      icon: <MessageSquare className="w-5 h-5" />,
      label: t("dashboard.chat"),
    },
    {
      id: "image",
      icon: <ImagePlus className="w-5 h-5" />,
      label: t("dashboard.imageGenerator"),
    },
    {
      id: "qr",
      icon: <QrCode className="w-5 h-5" />,
      label: t("dashboard.qrGenerator"),
    },
    {
      id: "svg",
      icon: <FileText className="w-5 h-5" />,
      label: t("dashboard.svgGenerator"),
    },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case "chat":
        return <ChatWithLlama />;
      case "image":
        return <ImageGenerator isPremium={isPremium} />;
      case "qr":
        return <QRCodeGenerator isPremium={isPremium} />;
      case "svg":
        return <SVGCreator isPremium={isPremium} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700`}
      >
        <div className="h-full flex flex-col">
          {/* Credits Display */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg p-4 text-white">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <Coins className="w-6 h-6 mr-2" />
                  <span className="font-semibold">Credits</span>
                </div>
                <button
                  onClick={() => setShowCreditsPurchaseModal(true)}
                  className="p-1.5 bg-white bg-opacity-20 rounded-full hover:bg-opacity-30 transition-colors"
                  title="Buy Credits"
                >
                  <Plus className="w-4 h-4" />
                </button>
              </div>
              <div className="text-2xl font-bold">
                {loading ? (
                  <div className="animate-pulse bg-white bg-opacity-20 h-8 w-20 rounded"></div>
                ) : credits?.credits === -1 ? ( // Changed condition to check for -1
                  "∞"
                ) : (
                  credits?.credits || 0
                )}
              </div>
              <div className="text-sm text-white/80">
                {credits?.credits === -1 // Changed condition to check for -1
                  ? "Unlimited Access"
                  : "Available Credits"}
              </div>
            </div>
          </div>

          {/* Navigation Items */}
          <nav className="flex-1 p-4 space-y-2">
            {sidebarItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id as ActiveTab)}
                className={`w-full flex items-center px-4 py-3 rounded-lg transition-colors ${
                  activeTab === item.id
                    ? "bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400"
                    : "text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                }`}
              >
                {item.icon}
                <span className="ml-3 font-medium">{item.label}</span>
              </button>
            ))}
          </nav>

          {/* Subscription Status */}
          <div className="p-4 border-t border-gray-200 dark:border-gray-700">
            <button
              onClick={() => setShowSubscriptionModal(true)}
              className="w-full flex items-center justify-center px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg hover:opacity-90 transition-opacity"
            >
              <CreditCard className="w-4 h-4 mr-2" />
              {subscription?.status === "none"
                ? t("dashboard.subscribe")
                : t("dashboard.manageSubscription")}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Sidebar Toggle */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed bottom-4 left-4 z-40 md:hidden bg-indigo-600 text-white p-3 rounded-full shadow-lg"
      >
        <Menu className="w-6 h-6" />
      </button>

      {/* Main Content */}
      <main
        className={`flex-1 transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : "ml-0"
        }`}
      >
        <div className="max-w-4xl mx-auto px-4 py-8">{renderContent()}</div>
      </main>

      {/* Modals */}
      {showSubscriptionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-2xl font-bold flex items-center text-gray-900 dark:text-white">
                  <Sparkles className="text-indigo-500 mr-2" />
                  {subscription?.status === "none"
                    ? t("dashboard.choosePlan")
                    : t("dashboard.manageSubscription")}
                </h2>
                <p className="text-gray-600 dark:text-gray-400 mt-1">
                  {t("dashboard.selectPlanDescription")}
                </p>
              </div>
              <button
                onClick={() => setShowSubscriptionModal(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                ✕
              </button>
            </div>

            <SubscriptionPlans onSelectPlan={handleSubscribe} />

            <div className="mt-6 text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {t("dashboard.moneyBackGuarantee")}
              </p>
              {subscription?.status !== "none" && (
                <p className="text-sm text-indigo-600 dark:text-indigo-400 mt-2">
                  {t("dashboard.currentPlan")}:{" "}
                  <span className="font-semibold capitalize">
                    {subscription?.status}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Credits Purchase Modal */}
      {showCreditsPurchaseModal && (
        <CreditsPurchase
          onClose={() => setShowCreditsPurchaseModal(false)}
          onPurchase={fetchUserData}
        />
      )}

      {/* Payment Frame */}
      {paymentToken && (
        <PaymentFrame
          iframeToken={paymentToken}
          onClose={() => setPaymentToken(null)}
          onMessage={handlePaymentMessage}
        />
      )}
    </div>
  );
}

export default Dashboard;
