import React from 'react';
import { Link } from 'react-router-dom';
import { MessageSquare, Image, QrCode, File, ArrowRight, Calendar } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';
import SEOHead from '../components/SEOHead';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  image: string;
  date: string;
  category: 'chat' | 'image' | 'qr' | 'svg';
  readTime: string;
}

function Blog() {
  const { t } = useLanguage();

  const blogPosts: BlogPost[] = [
    {
      id: 'llama-chat-assistant',
      title: 'Llama Chat Assistant ile Yapay Zeka Sohbeti',
      excerpt: 'Meta\'nın güçlü dil modeli Llama ile nasıl etkileşime geçebilir ve yapay zeka destekli sohbet deneyimini yaşayabilirsiniz?',
      image: 'https://images.unsplash.com/photo-1511885663737-eea53f6d6187?q=80&w=3948&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      date: '2024-03-21',
      category: 'chat',
      readTime: '5 dk'
    },
    {
      id: 'ai-image-generation',
      title: 'Yapay Zeka ile Görsel Oluşturma',
      excerpt: 'Metinden görsel oluşturma teknolojisi ile hayal ettiğiniz görselleri saniyeler içinde nasıl oluşturabilirsiniz?',
      image: 'https://ideogram.ai/assets/image/lossless/response/bdXgxqi8SqK1gdUTAsXNdQ',
      date: '2024-03-20',
      category: 'image',
      readTime: '4 dk'
    },
    {
      id: 'artistic-qr-codes',
      title: 'Sanatsal QR Kodlar Oluşturun',
      excerpt: 'Sıradan QR kodlarını unutun! Yapay zeka destekli sanatsal QR kod oluşturma aracımız ile markanızı farklılaştırın.',
      image: 'https://res.cloudinary.com/ddqroul7l/image/upload/fl_preserve_transparency/v1731490590/405e12630857df375ce5aa22306c0b3542686612-1024x1024_pnbkjy.jpg?_s=public-apps',
      date: '2024-03-19',
      category: 'qr',
      readTime: '3 dk'
    },
    {
      id: 'svg-generation',
      title: 'Metinden SVG İllüstrasyonlar',
      excerpt: 'Yapay zeka ile metin açıklamalarından profesyonel SVG illüstrasyonlar oluşturmanın incelikleri.',
      image: 'https://images.unsplash.com/photo-1634942537034-2531766767d1',
      date: '2024-03-18',
      category: 'svg',
      readTime: '6 dk'
    }
  ];

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case 'chat':
        return <MessageSquare className="w-5 h-5" />;
      case 'image':
        return <Image className="w-5 h-5" />;
      case 'qr':
        return <QrCode className="w-5 h-5" />;
      case 'svg':
        return <File className="w-5 h-5" />;
      default:
        return null;
    }
  };

  return (
    <>
      <SEOHead 
        title="AI Fun Hub Blog - AI Tools & Tutorials"
        description="Discover the latest articles about our AI-powered tools including chat assistant, image generation, QR code creation, and SVG illustrations."
      />
      
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        {/* Hero Section */}
        <div className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl md:text-5xl font-bold text-center mb-6">
              AI Fun Hub Blog
            </h1>
            <p className="text-xl text-center text-indigo-100 max-w-2xl mx-auto">
              Yapay zeka araçlarımız hakkında en güncel bilgiler, ipuçları ve kullanım kılavuzları
            </p>
          </div>
        </div>

        {/* Blog Posts Grid */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid md:grid-cols-2 gap-8">
            {blogPosts.map((post) => (
              <Link
                key={post.id}
                to={`/blog/${post.id}`}
                className="group bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
              >
                <div className="aspect-video relative overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                  />
                  <div className="absolute top-4 left-4">
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-white/90 dark:bg-gray-800/90 text-gray-900 dark:text-white">
                      {getCategoryIcon(post.category)}
                      <span className="ml-1.5">
                        {post.category.charAt(0).toUpperCase() + post.category.slice(1)}
                      </span>
                    </span>
                  </div>
                </div>
                
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-3">
                    <Calendar className="w-4 h-4 mr-1.5" />
                    {new Date(post.date).toLocaleDateString('tr-TR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                    <span className="mx-2">•</span>
                    {post.readTime}
                  </div>
                  
                  <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-3 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors">
                    {post.title}
                  </h2>
                  
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    {post.excerpt}
                  </p>
                  
                  <div className="flex items-center text-indigo-600 dark:text-indigo-400 font-medium">
                    Devamını Oku
                    <ArrowRight className="w-4 h-4 ml-1.5 group-hover:translate-x-1 transition-transform" />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;