const translations = {
  tr: {
    // Navigation
    'nav.home': 'Ana Sayfa',
    'nav.dashboard': 'Panel',
    'nav.about': 'Hakkımızda',
    'nav.login': 'Giriş',
    'nav.register': 'Kayıt Ol',
    'nav.logout': 'Çıkış',

    // Home Page
    'home.hero.title': 'AI Fun Hub\'a Hoş Geldiniz',
    'home.hero.subtitle': 'Büyüyen eğlenceli ve kullanışlı araç koleksiyonumuzla yapay zekanın gücünü deneyimleyin. Mesaj analizine bugün başlayın; çok yakında daha heyecan verici özellikler gelecek!',
    'home.getStarted': 'Başlayın',
    'home.tryDemo': 'Deneyin',
    'home.availableTools': 'Kullanılabilir Araçlar',
    'home.cta.title': 'AI Araçlarını Bugün Kullanmaya Başlayın',
    'home.cta.subtitle': 'Mesaj analiz aracımızla halihazırda yapay zekanın gücünü deneyimleyen, giderek büyüyen kullanıcı topluluğumuza katılın. Daha heyecan verici özellikler çok yakında!',
    'home.cta.button': 'Ücretsiz Deneme',
    'home.tools.title1': 'Müzik Oluşturucu',
    'home.tools.desc1': 'AI ile benzersiz müzikler oluşturun',
    'home.tools.title2': 'Görselden 3D Asset Oluşturucu',
    'home.tools.desc2': 'Yüklediğiniz görselden 3D asset oluşturun',
    'home.tools.title3': 'Görsel Oluşturucu',
    'home.tools.desc3': 'Benzersiz logolar, posterler ve gerçekçi görseller oluşturun',
    'home.tools.currentTitle1': 'Chat with Llama',
    'home.tools.currentDesc1': 'Gelişmiş Chat botu Llama ile sohbet edin.',

    // Dashboard
    'dashboard.title': 'AI Fun Hub Panel',
    'dashboard.subtitle': 'Şu anda Mesaj Analizi aracımızı sunuyoruz. Yakında daha fazla AI aracı geliyor!',
    'dashboard.desc': 'Heyecan verici yeni AI araçları üzerinde çalışıyoruz. Güncellemeler için bizi izlemeye devam edin!',
    'dashboard.new': 'Yakında',
    'dashboard.chat': 'Sohbet',
    'dashboard.imageGenerator': 'Görsel Oluşturucu',
    'dashboard.loading': 'Yükleniyor...',
    'dashboard.unlimitedCredits': 'Sınırsız Kredi',
    'dashboard.creditsRemaining': '{{credits}} Kredi Kaldı',
    'dashboard.credits': 'Kalan Krediniz',
    'dashboard.buyCredits': 'Kredi Satın Al',
    'dashboard.chatTitle': 'Llama ile Sohbet',
    'dashboard.chatDescription': 'Yapay zeka asistanımız ile sohbet edin',
    'dashboard.subscribe': 'Abone Ol',
    'dashboard.manageSubscription': 'Aboneliği Yönet',
    'dashboard.choosePlan': 'Plan Seçin',
    'dashboard.selectPlanDescription': 'Size en uygun planı seçin',
    'dashboard.moneyBackGuarantee': 'Elektronik ortamda derhal teslim edilen ve derhal yararlanma sağlanan bir hizmet/yazılım olduğu için iadesi mümkün değildir',
    'dashboard.currentPlan': 'Mevcut planınız',
    'dashboard.subscriptionStatus': 'Abonelik Durumu',
    'dashboard.none': 'Yok',
    'dashboard.expiresOn': 'Bitiş Tarihi',
    'dashboard.qrGenerator': 'QR Kod Oluşturucu',
    'dashboard.svgGenerator': 'SVG Oluşturucu',


    // Subscription
    'subscription.basic.title': 'Temel',
    'subscription.basic.feature1': '1000 kredi dahil',
    'subscription.basic.feature2': 'Tüm temel araçlara erişim',
    'subscription.basic.feature3': 'E-posta desteği',
    'subscription.basic.feature4': '30 gün geçerli',

    'subscription.premium.title': 'Premium',
    'subscription.premium.feature1': 'Sınırsız mesaj',
    'subscription.premium.feature2': 'Tüm premium araçlara erişim',
    'subscription.premium.feature3': 'Öncelikli destek',
    'subscription.premium.feature4': '30 gün geçerli',

    'subscription.enterprise.title': 'Kurumsal',
    'subscription.enterprise.feature1': 'Özel AI model eğitimi',
    'subscription.enterprise.feature2': 'Özel destek',
    'subscription.enterprise.feature3': 'API erişimi',
    'subscription.enterprise.feature4': 'Özel entegrasyonlar',

    'subscription.mostPopular': 'En Popüler',
    'subscription.perMonth': 'ay',
    'subscription.comingSoon': 'Çok Yakında',
    'subscription.subscribe': 'Abone Ol',
    // Gallery
    'gallery.title': 'Galeri',
    'gallery.subtitle': 'Ürettiğiniz tüm görseller burada',
    'gallery.noImages': 'Henüz görsel yok',
    'gallery.startGenerating': 'Koleksiyonunuzu oluşturmaya başlayın',
    'gallery.download': 'İndir',
    'gallery.delete': 'Sil',
    'gallery.deleteConfirm': 'Bu görseli silmek istediğinizden emin misiniz?',
    'gallery.deleteSuccess': 'Görsel başarıyla silindi',
    'gallery.deleteError': 'Görsel silinirken bir hata oluştu',
    'gallery.prompt': 'Prompt',
    'gallery.createdAt': 'Oluşturulma Tarihi',
    'gallery.close': 'Kapat',

    // Image Generator
    'imageGen.savedToGallery': 'Görsel galerinize kaydedildi',
    'imageGen.promptLabel': 'Görsel Açıklaması',
    'imageGen.promptPlaceholder': 'Oluşturmak istediğiniz görseli detaylı bir şekilde açıklayın...',
    'imageGen.aspectRatio': 'En-Boy Oranı',
    'imageGen.square': 'Kare',
    'imageGen.landscape': 'Yatay',
    'imageGen.portrait': 'Dikey',
    'imageGen.widescreen': 'Geniş Ekran',
    'imageGen.mobile': 'Mobil',
    'imageGen.generate': 'Görsel Oluştur',
    'imageGen.generating': 'Oluşturuluyor...',
    'imageGen.promptRequired': 'Lütfen bir görsel açıklaması girin',
    'imageGen.success': 'Görsel başarıyla oluşturuldu!',
    'imageGen.error': 'Görsel oluşturma başarısız oldu',
    'imageGen.download': 'Görseli İndir',
    'imageGen.clear': 'Temizle',
    'imageGen.insufficientCredits': 'Yetersiz kredi',
    'imageGen.creditsRequired': 'Bu işlem için 2 kredi gerekiyor',
    'imageGen.title': 'Ideagraf AI Görsel Oluşturucu Powered by Ideogram',
    'imageGen.description': 'Fikirlerinizi saniyeler içinde çarpıcı görsellere dönüştürün. Gerçekçi görseller, yenilikçi logolar ve posterler oluşturun.',
    'imageGen.premiumFeature': 'Premium Özellik',
    'imageGen.upgradeRequired': 'Bu özelliği kullanmak için Premium üyelik gerekiyor',
    'imageGen.upgradeNow': 'Şimdi Yükselt',
    // Styles
    'imageGen.style': 'Stil',
    'imageGen.styles.none': 'Stil Yok',
    'imageGen.styles.auto': 'Otomatik',
    'imageGen.styles.general': 'Genel',
    'imageGen.styles.realistic': 'Gerçekçi',
    'imageGen.styles.design': 'Tasarım',
    'imageGen.styles.render3d': '3D Render',
    'imageGen.styles.anime': 'Anime',
    // Resolutions
    'imageGen.resolution': 'Çözünürlük',
    'imageGen.resolutions.standard': 'Standart (1024x1024)',
    'imageGen.resolutions.high': 'Yüksek (2048x2048)',
    'imageGen.resolutions.ultra': 'Ultra (4096x4096)',
    // QR Code Generator
    'qrGen.title': 'Sanatsal QR Kod Oluşturucu',
    'qrGen.description': 'Sıradan QR kodlarını sanat eserine dönüştürün',
    'qrGen.urlLabel': 'URL',
    'qrGen.urlRequired': 'Lütfen bir URL girin',
    'qrGen.styleLabel': 'Stil Açıklaması',
    'qrGen.stylePlaceholder': 'QR kodunuzun görünümünü açıklayın... Örn: "yağlı boya manzara", "minimalist tasarım"',
    'qrGen.stylePremiumOnly': 'Özel stil seçenekleri Premium üyelere özeldir',
    'qrGen.scaleLabel': 'QR Kod Belirginliği',
    'qrGen.generate': 'QR Kod Oluştur',
    'qrGen.generating': 'Oluşturuluyor...',
    'qrGen.success': 'QR kod başarıyla oluşturuldu!',
    'qrGen.error': 'QR kod oluşturma başarısız oldu',
    'qrGen.download': 'QR Kodu İndir',
    'qrGen.clear': 'Temizle',
    'qrGen.insufficientCredits': 'Yetersiz kredi',
    'qrGen.customStylePremium': 'Özel stil seçenekleri Premium üyelere özeldir',
    'qrGen.premiumFeature': 'Premium Özellik',
    'qrGen.upgradeRequired': 'Bu özelliği kullanmak için Premium üyelik gerekiyor',
    'qrGen.savedToGallery': 'QR kodunuz galerinize kaydedildi',
  },
  en: {
    // Navigation
    'nav.home': 'Home',
    'nav.dashboard': 'Dashboard',
    'nav.about': 'About',
    'nav.login': 'Login',
    'nav.register': 'Register',
    'nav.logout': 'Logout',

    // Home Page
    'home.hero.title': 'Welcome to AI Fun Hub',
    'home.hero.subtitle': 'Experience the power of AI with our growing collection of fun and useful tools. Start with message analysis today, with more exciting features coming soon!',
    'home.getStarted': 'Get Started',
    'home.tryDemo': 'Try Demo',
    'home.availableTools': 'Available Tools',
    'home.cta.title': 'Start Using AI Tools Today',
    'home.cta.subtitle': 'Join our growing community of users who are already experiencing the power of AI with our message analysis tool. More exciting features coming soon!',
    'home.cta.button': 'Start Free Trial',
    'home.tools.title1': 'Music Generator',
    'home.tools.desc1': 'Create unique music with AI',
    'home.tools.title2': 'Image to 3D Generator',
    'home.tools.desc2': 'Generate 3D assets from images',
    'home.tools.title3': 'Image Generator',
    'home.tools.desc3': 'Create realistic images, innovative logos and posters',
    'home.tools.currentTitle1': 'Chat with Llama',
    'home.tools.currentDesc1': 'Chat with our advanced AI assistant powered by Llama',

    // Dashboard
    'dashboard.title': 'AI Fun Hub Dashboard',
    'dashboard.subtitle': 'Currently featuring our Message Analysis tool. More AI tools coming soon!',
    'dashboard.desc': 'We\'re working on exciting new AI tools to add to your dashboard. Stay tuned for updates!',
    'dashboard.new': 'Coming Soon',
    'dashboard.chat': 'Chat',
    'dashboard.imageGenerator': 'Image Generator',
    'dashboard.loading': 'Loading...',
    'dashboard.unlimitedCredits': 'Unlimited Credits',
    'dashboard.creditsRemaining': '{{credits}} Credits Remaining',
    'dashboard.credits': 'Available Credits',
    'dashboard.buyCredits': 'Buy Credits',
    'dashboard.chatTitle': 'Chat with Llama',
    'dashboard.chatDescription': 'Have a conversation with our AI assistant',
    'dashboard.subscribe': 'Subscribe Now',
    'dashboard.manageSubscription': 'Manage Subscription',
    'dashboard.choosePlan': 'Choose Your Plan',
    'dashboard.selectPlanDescription': 'Select a plan that works best for you',
    'dashboard.moneyBackGuarantee': 'No money-back guarantee',
    'dashboard.currentPlan': 'Current Plan',
    'dashboard.subscriptionStatus': 'Subscription Status',
    'dashboard.none': 'None',
    'dashboard.expiresOn': 'Expires On',
    'dashboard.qrGenerator': 'QR Code Generator',
    'dashboard.svgGenerator': 'SVG Generator',

    // Subscription
    'subscription.basic.title': 'Basic',
    'subscription.basic.feature1': '1000 credits included',
    'subscription.basic.feature2': 'Access to all basic tools',
    'subscription.basic.feature3': 'Email support',
    'subscription.basic.feature4': 'Valid for 30 days',

    'subscription.premium.title': 'Premium',
    'subscription.premium.feature1': 'Unlimited messages',
    'subscription.premium.feature2': 'Access to all premium tools',
    'subscription.premium.feature3': 'Priority support',
    'subscription.premium.feature4': 'Valid for 30 days',

    'subscription.enterprise.title': 'Enterprise',
    'subscription.enterprise.feature1': 'Custom AI model training',
    'subscription.enterprise.feature2': 'Dedicated support',
    'subscription.enterprise.feature3': 'API access',
    'subscription.enterprise.feature4': 'Custom integrations',

    'subscription.mostPopular': 'Most Popular',
    'subscription.perMonth': 'month',
    'subscription.comingSoon': 'Coming Soon',
    'subscription.subscribe': 'Subscribe',
    // Gallery
    'gallery.title': 'Gallery',
    'gallery.subtitle': 'All your generated images in one place',
    'gallery.noImages': 'No images yet',
    'gallery.startGenerating': 'Start generating images to build your collection',
    'gallery.download': 'Download',
    'gallery.delete': 'Delete',
    'gallery.deleteConfirm': 'Are you sure you want to delete this image?',
    'gallery.deleteSuccess': 'Image deleted successfully',
    'gallery.deleteError': 'Failed to delete image',
    'gallery.prompt': 'Prompt',
    'gallery.createdAt': 'Created At',
    'gallery.close': 'Close',



    // Image Generator
    'imageGen.savedToGallery': 'Image saved to your gallery',
    'imageGen.promptLabel': 'Image Description',
    'imageGen.promptPlaceholder': 'Describe the image you want to generate in detail...',
    'imageGen.aspectRatio': 'Aspect Ratio',
    'imageGen.square': 'Square',
    'imageGen.landscape': 'Landscape',
    'imageGen.portrait': 'Portrait',
    'imageGen.widescreen': 'Widescreen',
    'imageGen.mobile': 'Mobile',
    'imageGen.generate': 'Generate Image',
    'imageGen.generating': 'Generating...',
    'imageGen.promptRequired': 'Please enter an image description',
    'imageGen.success': 'Image generated successfully!',
    'imageGen.error': 'Failed to generate image',
    'imageGen.download': 'Download Image',
    'imageGen.clear': 'Clear',
    'imageGen.insufficientCredits': 'Insufficient credits',
    'imageGen.creditsRequired': 'This action requires 2 credits',
    'imageGen.title': 'Ideogram AI Image Generator',
    'imageGen.description': 'Transform your ideas into stunning visuals in seconds. Create realistic images, innovative logos, and posters.',
    'imageGen.premiumFeature': 'Premium Feature',
    'imageGen.upgradeRequired': 'Premium subscription required to use this feature',
    'imageGen.upgradeNow': 'Upgrade Now',

    // Styles
    'imageGen.style': 'Style',
    'imageGen.styles.none': 'No Style',
    'imageGen.styles.auto': 'Auto',
    'imageGen.styles.general': 'General',
    'imageGen.styles.realistic': 'Realistic',
    'imageGen.styles.design': 'Design',
    'imageGen.styles.render3d': '3D Render',
    'imageGen.styles.anime': 'Anime',

    // Resolutions
    'imageGen.resolution': 'Resolution',
    'imageGen.resolutions.standard': 'Standard (1024x1024)',
    'imageGen.resolutions.high': 'High (2048x2048)',
    'imageGen.resolutions.ultra': 'Ultra (4096x4096)',

    // QR Code Generator
    'qrGen.title': 'Artistic QR Code Generator',
    'qrGen.description': 'Transform ordinary QR codes into works of art',
    'qrGen.urlLabel': 'URL',
    'qrGen.urlRequired': 'Please enter a URL',
    'qrGen.styleLabel': 'Style Description',
    'qrGen.stylePlaceholder': 'Describe how your QR code should look... E.g., "oil painting landscape", "minimalist design"',
    'qrGen.stylePremiumOnly': 'Custom style options are available for Premium members',
    'qrGen.scaleLabel': 'QR Code Clarity',
    'qrGen.generate': 'Generate QR Code',
    'qrGen.generating': 'Generating...',
    'qrGen.success': 'QR code generated successfully!',
    'qrGen.error': 'Failed to generate QR code',
    'qrGen.download': 'Download QR Code',
    'qrGen.clear': 'Clear',
    'qrGen.insufficientCredits': 'Insufficient credits',
    'qrGen.customStylePremium': 'Custom style options are available for Premium members',
    'qrGen.premiumFeature': 'Premium Feature',
    'qrGen.upgradeRequired': 'Premium subscription required to use this feature',
    'qrGen.savedToGallery': 'Your QR code has been saved to your gallery',
  }
};

export default translations;