import React, { useState } from 'react';
import { ImagePlus, Download, Loader, RefreshCcw, LayoutTemplate, Sparkles, Lock } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useLanguage } from '../context/LanguageContext';
import { config } from '../config/env';
import SubscriptionPlans from './SubscriptionPlans';

type AspectRatioType = "1:1" | "4:3" | "3:4" | "16:9" | "9:16";
type StyleType = "None" | "Auto" | "General" | "Realistic" | "Design" | "Render 3D" | "Anime";
type ResolutionType = "1024x1024" | "2048x2048" | "4096x4096";

interface ImageGeneratorProps {
  isPremium?: boolean;
}

function ImageGenerator({ isPremium = false }: ImageGeneratorProps) {
  const [prompt, setPrompt] = useState('');
  const [aspectRatio, setAspectRatio] = useState<AspectRatioType>("1:1");
  const [styleType, setStyleType] = useState<StyleType>("None");
  const [resolution, setResolution] = useState<ResolutionType>("1024x1024");
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [s3Key, setS3Key] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { t } = useLanguage();

  const aspectRatios: { value: AspectRatioType; label: string }[] = [
    { value: "1:1", label: t('imageGen.square') },
    { value: "4:3", label: t('imageGen.landscape') },
    { value: "3:4", label: t('imageGen.portrait') },
    { value: "16:9", label: t('imageGen.widescreen') },
    { value: "9:16", label: t('imageGen.mobile') },
  ];

  const styles: { value: StyleType; label: string }[] = [
    { value: "None", label: t('imageGen.styles.none') },
    { value: "Auto", label: t('imageGen.styles.auto') },
    { value: "General", label: t('imageGen.styles.general') },
    { value: "Realistic", label: t('imageGen.styles.realistic') },
    { value: "Design", label: t('imageGen.styles.design') },
    { value: "Render 3D", label: t('imageGen.styles.render3d') },
    { value: "Anime", label: t('imageGen.styles.anime') },
  ];

  const resolutions: { value: ResolutionType; label: string }[] = [
    { value: "1024x1024", label: t('imageGen.resolutions.standard') },
    { value: "2048x2048", label: t('imageGen.resolutions.high') },
    { value: "4096x4096", label: t('imageGen.resolutions.ultra') },
  ];

  const handlePremiumFeatureClick = () => {
    if (!isPremium) {
      setShowUpgradeModal(true);
    }
  };

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      toast.error(t('imageGen.promptRequired'));
      return;
    }

    setIsGenerating(true);
    setProgress('');
    setImageUrl(null);
    setS3Key(null);

    try {
      const response = await fetch(`${config.API_URL}/api/image/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          prompt,
          aspectRatio,
          styleType: isPremium ? styleType : undefined,
          resolution: isPremium ? resolution : "1024x1024"
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        if (response.status === 402) {
          toast.error(t('imageGen.insufficientCredits'));
          return;
        }
        throw new Error(error.message || 'Generation failed');
      }

      const reader = response.body?.getReader();
      if (!reader) throw new Error('No reader available');

      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        
        if (done) break;
        
        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        
        buffer = lines.pop() || '';
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              
              switch (data.type) {
                case 'progress':
                  setProgress(data.data);
                  break;
                case 'output':
                  setImageUrl(data.url);
                  setS3Key(data.s3Key);
                  toast.success(t('imageGen.success'));
                  break;
                case 'error':
                  throw new Error(data.message);
              }
            } catch (e) {
              console.error('Event parsing error:', e);
            }
          }
        }
      }
    } catch (error) {
      console.error('Image generation error:', error);
      toast.error(t('imageGen.error'));
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownload = async () => {
    if (imageUrl) {
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = `generated-image-${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center justify-center gap-2">
            <Sparkles className="w-6 h-6 text-indigo-600" />
            {t('imageGen.title')}
          </h2>
          <p className="mt-2 text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t('imageGen.description')}
          </p>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            {t('imageGen.promptLabel')}
          </label>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full h-32 p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-none bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            placeholder={t('imageGen.promptPlaceholder')}
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            <LayoutTemplate className="w-4 h-4 inline-block mr-1" />
            {t('imageGen.aspectRatio')}
          </label>
          <div className="grid grid-cols-5 gap-2">
            {aspectRatios.map(({ value, label }) => (
              <button
                key={value}
                onClick={() => setAspectRatio(value)}
                className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                  aspectRatio === value
                    ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-300'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2 flex items-center">
            {t('imageGen.style')}
            {!isPremium && (
              <span className="ml-2 text-xs bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-400 px-2 py-1 rounded-full flex items-center">
                <Lock className="w-3 h-3 mr-1" />
                {t('imageGen.premiumFeature')}
              </span>
            )}
          </label>
          <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-7 gap-2">
            {styles.map(({ value, label }) => (
              <button
                key={value}
                onClick={() => isPremium ? setStyleType(value) : handlePremiumFeatureClick()}
                className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors relative ${
                  styleType === value && isPremium
                    ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-300'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                } ${!isPremium && 'opacity-50 cursor-not-allowed hover:bg-gray-200 dark:hover:bg-gray-600'}`}
              >
                {label}
                {!isPremium && <Lock className="w-3 h-3 absolute top-1 right-1" />}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2 flex items-center">
            {t('imageGen.resolution')}
            {!isPremium && (
              <span className="ml-2 text-xs bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-400 px-2 py-1 rounded-full flex items-center">
                <Lock className="w-3 h-3 mr-1" />
                {t('imageGen.premiumFeature')}
              </span>
            )}
          </label>
          <div className="grid grid-cols-3 gap-2">
            {resolutions.map(({ value, label }) => (
              <button
                key={value}
                onClick={() => isPremium ? setResolution(value) : handlePremiumFeatureClick()}
                className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors relative ${
                  resolution === value && isPremium
                    ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-300'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                } ${!isPremium && 'opacity-50 cursor-not-allowed hover:bg-gray-200 dark:hover:bg-gray-600'}`}
              >
                {label}
                {!isPremium && <Lock className="w-3 h-3 absolute top-1 right-1" />}
              </button>
            ))}
          </div>
        </div>

        <button
          onClick={handleGenerate}
          disabled={isGenerating}
          className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-3 px-6 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 flex items-center justify-center"
        >
          {isGenerating ? (
            <>
              <Loader className="animate-spin mr-2" />
              {t('imageGen.generating')}
            </>
          ) : (
            <>
              <ImagePlus className="mr-2" />
              {t('imageGen.generate')}
            </>
          )}
        </button>

        {progress && (
          <div className="mt-4 text-center text-gray-600 dark:text-gray-300">
            {progress}
          </div>
        )}

        {imageUrl && (
          <div className="mt-6 space-y-4">
            <div className="relative rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700">
              <img
                src={imageUrl}
                alt="Generated image"
                className="w-full h-auto"
                loading="lazy"
              />
              <div className="absolute top-4 right-4 space-x-2">
                <button
                  onClick={handleDownload}
                  className="p-2 bg-white/90 dark:bg-gray-800/90 rounded-full hover:bg-white dark:hover:bg-gray-800 transition-colors shadow-lg"
                  title={t('imageGen.download')}
                >
                  <Download className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                </button>
                <button
                  onClick={() => {
                    setImageUrl(null);
                    setS3Key(null);
                  }}
                  className="p-2 bg-white/90 dark:bg-gray-800/90 rounded-full hover:bg-white dark:hover:bg-gray-800 transition-colors shadow-lg"
                  title={t('imageGen.clear')}
                >
                  <RefreshCcw className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                </button>
              </div>
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
              {t('imageGen.savedToGallery')}
            </p>
          </div>
        )}
      </div>

      {/* Upgrade Modal */}
      {showUpgradeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-4xl w-full">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {t('imageGen.upgradeRequired')}
              </h3>
              <button
                onClick={() => setShowUpgradeModal(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                ✕
              </button>
            </div>
            <SubscriptionPlans onSelectPlan={(plan) => {
              // Handle subscription
              setShowUpgradeModal(false);
            }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGenerator;