import { useState } from 'react';
import { Shield, CreditCard } from 'lucide-react';
import { toast } from 'react-hot-toast';
import PaymentFrame from './PaymentFrame';
import { createPayment } from '../services/paymentService';

interface CreditsPurchaseProps {
  onClose: () => void;
  onPurchase: () => void;
}

interface Plan {
  credits: number;
  price: number;
  popular: boolean;
  perCredit: string;
}

function CreditsPurchase({ onClose, onPurchase }: CreditsPurchaseProps) {
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const [merchantOid, setMerchantOid] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const plans: Plan[] = [
    {
      credits: 10,
      price: 100,
      popular: false,
      perCredit: '10'
    },
    {
      credits: 50,
      price: 400,
      popular: true,
      perCredit: '8'
    },
    {
      credits: 100,
      price: 700,
      popular: false,
      perCredit: '7'
    }
  ];

  const handlePaymentMessage = async (status: 'success' | 'failed') => {
    if (status === 'success') {
      toast.success('Ödeme başarıyla tamamlandı!');
      if (selectedPlan) {
        await onPurchase();
      }
      onClose();
    } else {
      toast.error('Ödeme başarısız oldu. Lütfen tekrar deneyin.');
      setPaymentToken(null);
      setMerchantOid(null);
    }
  };

  const handlePurchase = async () => {
    if (!selectedPlan) {
      toast.error('Lütfen bir plan seçin');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await createPayment(selectedPlan.price, selectedPlan.credits);
      
      if (response.success && response.params?.iframe_token) {
        setPaymentToken(response.params.iframe_token);
        setMerchantOid(response.params.merchant_oid as string);
        console.log('Payment token received:', response.params.iframe_token);
      } else {
        throw new Error('Ödeme başlatılamadı. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      console.error('Payment creation error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Ödeme işlemi başlatılamadı';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl max-w-4xl w-full p-6 relative max-h-[90vh] overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          ✕
        </button>

        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-2">Kredi Satın Al</h2>
          <p className="text-gray-600">Planınızı seçin ve güvenli ödeme yapın</p>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
            {error}
          </div>
        )}

        {!paymentToken ? (
          <>
            <div className="grid md:grid-cols-3 gap-6 mb-8">
              {plans.map((plan) => (
                <div
                  key={plan.credits}
                  onClick={() => setSelectedPlan(plan)}
                  className={`relative bg-white rounded-xl border-2 cursor-pointer transition-all ${
                    selectedPlan?.credits === plan.credits
                      ? 'border-indigo-500 shadow-lg'
                      : 'border-gray-200 hover:border-indigo-200'
                  } p-6 flex flex-col`}
                >
                  {plan.popular && (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                      <span className="bg-indigo-500 text-white text-sm py-1 px-3 rounded-full">
                        En Popüler
                      </span>
                    </div>
                  )}

                  <div className="mb-4">
                    <div className="flex items-baseline mb-2">
                      <span className="text-3xl font-bold">{plan.price} TL</span>
                    </div>
                    <div className="text-lg font-semibold text-gray-900">
                      {plan.credits} Kredi
                    </div>
                    <div className="text-sm text-gray-500">
                      {plan.perCredit} TL / kredi
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <button
              onClick={handlePurchase}
              disabled={loading || !selectedPlan}
              className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-indigo-700 transition-colors disabled:opacity-50 flex items-center justify-center"
            >
              {loading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  İşleniyor...
                </span>
              ) : (
                <>
                  <CreditCard className="mr-2" />
                  {selectedPlan ? `${selectedPlan.price} TL Öde` : 'Plan Seçin'}
                </>
              )}
            </button>

            <div className="flex items-center justify-center gap-2 text-sm text-gray-500 mt-6">
              <Shield className="w-4 h-4" />
              Güvenli ödeme işlemi
            </div>
          </>
        ) : (
          <PaymentFrame 
            iframeToken={paymentToken}
            onMessage={handlePaymentMessage}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
}

export default CreditsPurchase;