import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { toast } from 'react-hot-toast';
import api from '../../services/api';

function PaymentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkCredits = async () => {
      try {
        // Get updated credits
        const response = await api.getCredits();
        toast.success(`Ödeme başarıyla tamamlandı! Yeni kredi bakiyeniz: ${response.credits}`);
      } catch (error) {
        console.error('Error checking credits:', error);
        toast.success('Ödeme başarıyla tamamlandı!');
      } finally {
        // Redirect to dashboard after 3 seconds
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      }
    };

    checkCredits();

    // Cleanup timeout on unmount
    return () => {
      const timeouts = setTimeout(() => {}, 0);
      for (let i = 0; i <= timeouts; i++) {
        clearTimeout(i);
      }
    };
  }, [navigate]);

  return (
    <div className="min-h-[calc(100vh-4rem)] flex items-center justify-center">
      <div className="text-center bg-white p-8 rounded-xl shadow-lg max-w-md w-full mx-4">
        <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
        <h1 className="text-2xl font-bold mb-2">Ödeme Başarılı!</h1>
        <p className="text-gray-600 mb-4">
          Ödemeniz başarıyla tamamlandı. Kredileriniz hesabınıza eklendi.
        </p>
        <p className="text-sm text-gray-500">
          Dashboard'a yönlendiriliyorsunuz...
        </p>
      </div>
    </div>
  );
}

export default PaymentSuccess;