import { Helmet } from 'react-helmet-async';

interface SEOHeadProps {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  imageUrl?: string;
}

function SEOHead({
  title = 'AI Fun Hub - Smart AI Tools & Chat Assistant',
  description = 'Yapay Zeka Eğlence Merkezi - Akıllı Yapay Zeka Araçları ve Sohbet Asistanı. Meta LLama ile gelişmiş AI deneyimi',
  canonicalUrl = 'https://www.aifunhub.com',
  imageUrl = 'https://www.aifunhub.com/og-image.jpg'
}: SEOHeadProps) {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
}

export default SEOHead;