import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-hot-toast';
import { useAuth } from '../context/AuthContext';
import { config } from '../config/env';
import GoogleSVG from '../../public/google.png';


function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const result = await fetch(`${config.API_URL}/api/auth/google`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            access_token: response.access_token,
          }),
        });

        if (!result.ok) {
          const errorData = await result.json();
          throw new Error(errorData.error || 'Google login failed');
        }

        const data = await result.json();
        login(data.token);
        toast.success('Google ile giriş başarılı!');
        navigate('/dashboard');
      } catch (error) {
        console.error('Google login error:', error);
        toast.error('Google ile giriş başarısız oldu');
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      toast.error('Google ile giriş başarısız oldu');
    },
    flow: 'implicit',
  });

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900 px-4">
      <div className="max-w-md w-full">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Giriş Yap</h2>
            <p className="text-gray-600 dark:text-gray-400 mt-2">
              AI Fun Hub'a hoş geldiniz!
            </p>
          </div>

          <button
            onClick={() => handleGoogleLogin()}
            className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors"
          >
            <img
              src={GoogleSVG}
              alt="Google"
              className="w-5 h-5 mr-2"
            />
            Google ile Devam Et
          </button>

          <div className="mt-6 text-center text-sm text-gray-600 dark:text-gray-400">
            <Link
              to="/about"
              className="font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
            >
              Hakkımızda
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;