import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Calendar, Clock, Tag } from 'lucide-react';
import SEOHead from '../components/SEOHead';

interface BlogContent {
  id: string;
  title: string;
  date: string;
  readTime: string;
  category: string;
  content: React.ReactNode;
  image: string;
}

function BlogPost() {
  const { postId } = useParams<{ postId: string }>();

  const blogPosts: Record<string, BlogContent> = {
    'llama-chat-assistant': {
      id: 'llama-chat-assistant',
      title: 'Llama Chat Assistant ile Yapay Zeka Sohbeti',
      date: '2024-03-21',
      readTime: '5 dk',
      category: 'Chat Assistant',
      image: 'https://images.unsplash.com/photo-1675557009875-436f73cc2d9e',
      content: (
        <div className="prose prose-indigo dark:prose-invert max-w-none">
          <p className="lead">
            Meta'nın geliştirdiği Llama dil modeli, yapay zeka sohbet deneyimini yeni bir seviyeye taşıyor. 
            AI Fun Hub'da sunduğumuz Llama Chat Assistant ile bu teknolojiyi nasıl kullanabileceğinizi keşfedelim.
          </p>

          <h2>Llama Chat Assistant Nedir?</h2>
          <p>
            Llama Chat Assistant, Meta'nın güçlü dil modeli Llama'yı kullanarak geliştirilmiş, 
            kullanıcı dostu bir sohbet arayüzüdür. Doğal dil işleme yetenekleri sayesinde, 
            sorularınıza detaylı ve anlamlı yanıtlar verebilir.
          </p>

          <h2>Öne Çıkan Özellikler</h2>
          <ul>
            <li>Doğal ve akıcı sohbet deneyimi</li>
            <li>Türkçe dil desteği</li>
            <li>Hızlı yanıt süresi</li>
            <li>Geniş bilgi tabanı</li>
            <li>Kişiselleştirilmiş yanıtlar</li>
          </ul>

          <h2>Nasıl Kullanılır?</h2>
          <p>
            Chat Assistant'ı kullanmak için öncelikle AI Fun Hub hesabınıza giriş yapmanız gerekiyor. 
            Ardından dashboard üzerinden Chat Assistant'a erişebilir ve sohbete başlayabilirsiniz.
          </p>

          <div className="my-8">
            <img
              src="https://images.unsplash.com/photo-1676299081847-824916de030a"
              alt="Llama Chat Assistant Arayüzü"
              className="rounded-xl shadow-lg"
            />
            <p className="text-sm text-gray-500 mt-2 text-center">
              Llama Chat Assistant'ın kullanıcı dostu arayüzü
            </p>
          </div>

          <h2>Kullanım İpuçları</h2>
          <ol>
            <li>Sorularınızı mümkün olduğunca net ve açık bir şekilde sorun</li>
            <li>Uzun ve karmaşık konuşmaları küçük parçalara bölün</li>
            <li>Yanıtları detaylandırmak için ek sorular sorun</li>
            <li>Farklı konularda deneyim kazanın</li>
          </ol>

          <h2>Premium Özellikler</h2>
          <p>
            Premium üyelik ile Chat Assistant'ın gelişmiş özelliklerine erişebilirsiniz:
          </p>
          <ul>
            <li>Sınırsız sohbet hakkı</li>
            <li>Öncelikli yanıt süresi</li>
            <li>Özelleştirilmiş AI modeli</li>
            <li>Sohbet geçmişi yedekleme</li>
          </ul>

          <div className="bg-indigo-50 dark:bg-indigo-900/30 rounded-xl p-6 my-8">
            <h3 className="text-lg font-semibold mb-4">Pro İpucu</h3>
            <p>
              En iyi sonuçları almak için, sorularınızı bağlam içeren detaylarla zenginleştirin. 
              Örneğin, "Python nedir?" yerine "Python programlama dilinin web geliştirme için 
              avantajları nelerdir?" şeklinde sorular sorun.
            </p>
          </div>
        </div>
      )
    },
    'ai-image-generation': {
      id: 'ai-image-generation',
      title: 'Yapay Zeka ile Görsel Oluşturma',
      date: '2024-03-20',
      readTime: '4 dk',
      category: 'Image Generation',
      image: 'https://images.unsplash.com/photo-1686191128892-3b37add4c844',
      content: (
        <div className="prose prose-indigo dark:prose-invert max-w-none">
          <p className="lead">
            AI Fun Hub'ın görsel oluşturma aracı ile metinden görsel oluşturma teknolojisinin 
            gücünü keşfedin. Hayal ettiğiniz görselleri saniyeler içinde gerçeğe dönüştürün.
          </p>

          <h2>Görsel Oluşturma Teknolojisi</h2>
          <p>
            Yapay zeka destekli görsel oluşturma aracımız, metin açıklamalarınızı detaylı ve 
            yüksek kaliteli görsellere dönüştürür. Gelişmiş AI modelleri sayesinde, her türlü 
            stil ve konseptte görsel üretebilirsiniz.
          </p>

          <div className="my-8">
            <img
              src="https://images.unsplash.com/photo-1683009427513-28e163402d16"
              alt="AI Görsel Oluşturma Örneği"
              className="rounded-xl shadow-lg"
            />
            <p className="text-sm text-gray-500 mt-2 text-center">
              AI ile oluşturulmuş örnek bir görsel
            </p>
          </div>

          <h2>Özellikler ve Avantajlar</h2>
          <ul>
            <li>Yüksek çözünürlüklü görsel çıktılar</li>
            <li>Çeşitli stil ve filtre seçenekleri</li>
            <li>Hızlı işlem süresi</li>
            <li>Kolay kullanım arayüzü</li>
            <li>Özelleştirilebilir parametreler</li>
          </ul>

          <h2>Kullanım Alanları</h2>
          <p>
            Görsel oluşturma aracı birçok farklı alanda kullanılabilir:
          </p>
          <ul>
            <li>Sosyal medya içerikleri</li>
            <li>Blog görselleri</li>
            <li>Ürün konsept tasarımları</li>
            <li>Sanatsal projeler</li>
            <li>Pazarlama materyalleri</li>
          </ul>

          <div className="bg-indigo-50 dark:bg-indigo-900/30 rounded-xl p-6 my-8">
            <h3 className="text-lg font-semibold mb-4">En İyi Sonuçlar İçin İpuçları</h3>
            <ul>
              <li>Detaylı ve açık prompt'lar kullanın</li>
              <li>Stil referanslarını belirtin</li>
              <li>Renk paletini tanımlayın</li>
              <li>Kompozisyon tercihlerinizi belirtin</li>
            </ul>
          </div>
        </div>
      )
    },
    'artistic-qr-codes': {
      id: 'artistic-qr-codes',
      title: 'Sanatsal QR Kodlar Oluşturun',
      date: '2024-03-19',
      readTime: '3 dk',
      category: 'QR Code Generation',
      image: 'https://images.unsplash.com/photo-1605236453806-6ff36851218e',
      content: (
        <div className="prose prose-indigo dark:prose-invert max-w-none">
          <p className="lead">
            Sıradan siyah-beyaz QR kodlarının ötesine geçin. AI Fun Hub'ın sanatsal QR kod 
            oluşturma aracı ile markanızı yansıtan benzersiz QR kodlar oluşturun.
          </p>

          <div className="my-8">
            <img
              src="https://images.unsplash.com/photo-1621768216002-5ac171876625"
              alt="Sanatsal QR Kod Örneği"
              className="rounded-xl shadow-lg"
            />
            <p className="text-sm text-gray-500 mt-2 text-center">
              Yapay zeka ile oluşturulmuş sanatsal bir QR kod örneği
            </p>
          </div>

          <h2>Neden Sanatsal QR Kodlar?</h2>
          <ul>
            <li>Marka kimliğinizi yansıtın</li>
            <li>Dikkat çekici tasarımlar oluşturun</li>
            <li>Kullanıcı etkileşimini artırın</li>
            <li>Pazarlama materyallerinizi zenginleştirin</li>
          </ul>

          <h2>Özellikler</h2>
          <p>
            QR kod oluşturma aracımız şu özellikleri sunar:
          </p>
          <ul>
            <li>Özel stil seçenekleri</li>
            <li>Renk paleti özelleştirme</li>
            <li>Logo entegrasyonu</li>
            <li>Yüksek tarama güvenilirliği</li>
            <li>Çeşitli boyut seçenekleri</li>
          </ul>

          <div className="bg-indigo-50 dark:bg-indigo-900/30 rounded-xl p-6 my-8">
            <h3 className="text-lg font-semibold mb-4">Tasarım İpuçları</h3>
            <ol>
              <li>Markanızın renklerini kullanın</li>
              <li>Kontrast oranına dikkat edin</li>
              <li>Test etmeden kullanmayın</li>
              <li>Yeterli boşluk bırakın</li>
            </ol>
          </div>
        </div>
      )
    },
    'svg-generation': {
      id: 'svg-generation',
      title: 'Metinden SVG İllüstrasyonlar',
      date: '2024-03-18',
      readTime: '6 dk',
      category: 'SVG Generation',
      image: 'https://images.unsplash.com/photo-1634942537034-2531766767d1',
      content: (
        <div className="prose prose-indigo dark:prose-invert max-w-none">
          <p className="lead">
            AI Fun Hub'ın SVG oluşturma aracı ile metin açıklamalarından profesyonel 
            vektörel illüstrasyonlar oluşturun. Ölçeklenebilir ve web dostu grafikler 
            için ideal çözüm.
          </p>

          <h2>SVG Oluşturma Teknolojisi</h2>
          <p>
            Yapay zeka modelimiz, metin açıklamalarınızı analiz ederek yüksek kaliteli 
            SVG illüstrasyonlara dönüştürür. Vektörel formatın avantajlarıyla, her boyutta 
            keskin ve net görseller elde edin.
          </p>

          <div className="my-8">
            <img
              src="https://images.unsplash.com/photo-1634942537034-2531766767d1"
              alt="SVG İllüstrasyon Örneği"
              className="rounded-xl shadow-lg"
            />
            <p className="text-sm text-gray-500 mt-2 text-center">
              AI ile oluşturulmuş SVG illüstrasyon örneği
            </p>
          </div>

          <h2>Kullanım Alanları</h2>
          <ul>
            <li>Web siteleri ve uygulamalar</li>
            <li>Logo tasarımları</li>
            <li>İkonlar ve simgeler</li>
            <li>İnfografikler</li>
            <li>Animasyonlar</li>
          </ul>

          <h2>Avantajlar</h2>
          <ul>
            <li>Sınırsız ölçeklendirme</li>
            <li>Küçük dosya boyutu</li>
            <li>CSS ile özelleştirme</li>
            <li>Animasyon desteği</li>
            <li>SEO dostu format</li>
          </ul>

          <div className="bg-indigo-50 dark:bg-indigo-900/30 rounded-xl p-6 my-8">
            <h3 className="text-lg font-semibold mb-4">Pro İpuçları</h3>
            <ul>
              <li>Stil açıklamalarını detaylı yapın</li>
              <li>Renk paletini önceden belirleyin</li>
              <li>Basit formlarla başlayın</li>
              <li>Optimize edilmiş çıktılar alın</li>
            </ul>
          </div>
        </div>
      )
    }
  };

  const post = postId ? blogPosts[postId] : null;

  if (!post) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Gönderi bulunamadı</h1>
          <Link
            to="/blog"
            className="text-indigo-600 dark:text-indigo-400 hover:underline"
          >
            Blog'a dön
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOHead
        title={`${post.title} - AI Fun Hub Blog`}
        description={post.content.toString().slice(0, 160)}
        imageUrl={post.image}
      />

      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        {/* Hero Image */}
        <div className="h-[40vh] relative">
          <img
            src={post.image}
            alt={post.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900/75 to-transparent" />
        </div>

        {/* Content */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 -mt-32 relative">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-8 mb-8">
            {/* Back Button */}
            <Link
              to="/blog"
              className="inline-flex items-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white mb-8"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Blog'a dön
            </Link>

            {/* Title & Meta */}
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-6">
              {post.title}
            </h1>

            <div className="flex flex-wrap items-center gap-4 text-sm text-gray-600 dark:text-gray-400 mb-8">
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-1.5" />
                {new Date(post.date).toLocaleDateString('tr-TR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-1.5" />
                {post.readTime}
              </div>
              <div className="flex items-center">
                <Tag className="w-4 h-4 mr-1.5" />
                {post.category}
              </div>
            </div>

            {/* Content */}
            {post.content}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogPost;