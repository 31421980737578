import { useLanguage } from '../context/LanguageContext';

function LanguageSelector() {
  const { language, setLanguage } = useLanguage();

  return (
    <select
      value={language}
      onChange={(e) => setLanguage(e.target.value as 'tr' | 'en')}
      className="px-2 py-1 rounded-lg text-sm bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
    >
      <option value="tr">🇹🇷 TR</option>
      <option value="en">🇬🇧 EN</option>
    </select>
  );
}

export default LanguageSelector;