import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle, XCircle, Loader } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { config } from '../config/env';

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get('token');
      
      if (!token) {
        setStatus('error');
        return;
      }

      try {
        const response = await fetch(`${config.API_URL}/api/auth/verify-email?token=${token}`);
        const data = await response.json();

        if (response.ok) {
          setStatus('success');
          toast.success('E-posta adresiniz başarıyla doğrulandı!');
          setTimeout(() => navigate('/login'), 3000);
        } else {
          setStatus('error');
          toast.error(data.error || 'Doğrulama başarısız oldu');
        }
      } catch (error) {
        console.error('Verification error:', error);
        setStatus('error');
        toast.error('Doğrulama sırasında bir hata oluştu');
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="max-w-md w-full mx-4">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg text-center">
          {status === 'verifying' && (
            <>
              <Loader className="w-16 h-16 text-indigo-600 mx-auto mb-4 animate-spin" />
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                E-posta Doğrulanıyor
              </h2>
              <p className="text-gray-600 dark:text-gray-300">
                Lütfen bekleyin, e-posta adresiniz doğrulanıyor...
              </p>
            </>
          )}

          {status === 'success' && (
            <>
              <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                E-posta Doğrulandı!
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                E-posta adresiniz başarıyla doğrulandı. Giriş sayfasına yönlendiriliyorsunuz...
              </p>
              <button
                onClick={() => navigate('/login')}
                className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300 font-medium"
              >
                Giriş sayfasına git
              </button>
            </>
          )}

          {status === 'error' && (
            <>
              <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                Doğrulama Başarısız
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                E-posta doğrulama işlemi başarısız oldu. Lütfen tekrar deneyin veya destek ekibiyle iletişime geçin.
              </p>
              <button
                onClick={() => navigate('/')}
                className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300 font-medium"
              >
                Ana sayfaya dön
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;