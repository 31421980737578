import { Check, AlertCircle } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

interface Plan {
  type: 'basic' | 'premium' | 'enterprise';
  title: string;
  price: number;
  features: string[];
  popular?: boolean;
  comingSoon?: boolean;
}

interface SubscriptionPlansProps {
  onSelectPlan: (plan: Plan) => void;
}

function SubscriptionPlans({ onSelectPlan }: SubscriptionPlansProps) {
  const { t } = useLanguage();

  const plans: Plan[] = [
    {
      type: 'basic',
      title: t('subscription.basic.title'),
      price: 399,
      features: [
        '100 AI Görsel Kredisi',
        'Sınırsız LLAMA Chat',
        '30 Gün Geçerli'
      ]
    },
    {
      type: 'premium',
      title: t('subscription.premium.title'),
      price: 799,
      popular: true,
      features: [
        'Unlimited Chat Messages',
        '300 AI Image Credits',
        'Priority Support',
        'Advanced Features Access'
      ]
    },
    {
      type: 'enterprise',
      title: t('subscription.enterprise.title'),
      price: 2499,
      comingSoon: true,
      features: [
        'Custom AI Model Training',
        'Dedicated Support',
        'API Access',
        'Custom Integrations'
      ]
    }
  ];

  return (
    <div className="grid md:grid-cols-3 gap-8">
      {plans.map((plan) => (
        <div
          key={plan.type}
          className={`relative bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden ${
            plan.popular ? 'ring-2 ring-indigo-600' : ''
          }`}
        >
          {plan.popular && (
            <div className="absolute top-0 right-0 mt-4 mr-4">
              <span className="bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-300 px-3 py-1 rounded-full text-sm font-medium">
                {t('subscription.mostPopular')}
              </span>
            </div>
          )}

          <div className="p-6">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
              {plan.title}
            </h3>
            
            <div className="mt-4 flex items-baseline">
              <span className="text-4xl font-bold text-gray-900 dark:text-white">
                ₺{plan.price}
              </span>
              <span className="ml-2 text-gray-500 dark:text-gray-400">
                /{t('subscription.perMonth')}
              </span>
            </div>

            <ul className="mt-6 space-y-4">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <Check className="w-5 h-5 text-green-500 shrink-0 mr-2" />
                  <span className="text-gray-600 dark:text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>

            {plan.comingSoon ? (
              <div className="mt-8 flex items-center justify-center px-4 py-3 rounded-lg bg-gray-100 dark:bg-gray-700">
                <AlertCircle className="w-5 h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span className="text-gray-600 dark:text-gray-300 font-medium">
                  {t('subscription.comingSoon')}
                </span>
              </div>
            ) : (
              <button
                onClick={() => onSelectPlan(plan)}
                className={`mt-8 w-full py-3 px-4 rounded-lg font-medium ${
                  plan.popular
                    ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600'
                } transition-colors`}
              >
                {t('subscription.subscribe')}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default SubscriptionPlans;