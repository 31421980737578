import { useEffect, useRef } from 'react';

interface PaymentFrameProps {
  iframeToken: string;
  onClose: () => void;
  onMessage: (status: 'success' | 'failed') => void;
}

function PaymentFrame({ iframeToken, onClose, onMessage }: PaymentFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.paytr.com/js/iframeResizer.min.js';
    script.async = true;
    script.onload = () => {
      if (window.iFrameResize && iframeRef.current) {
        window.iFrameResize({
          heightCalculationMethod: 'lowestElement',
          sizeWidth: false,
          log: false,
          warningTimeout: 0
        }, '#paytriframe');
      }
    };
    document.body.appendChild(script);

    const handlePayTRMessage = (event: MessageEvent) => {
      if (event.origin === 'https://www.paytr.com') {
        try {
          const data = JSON.parse(event.data);
          if (data.status === 'success' || data.status === 'failed') {
            onMessage(data.status);
            onClose();
          }
        } catch (error) {
          console.error('Error parsing PayTR message:', error);
        }
      }
    };

    window.addEventListener('message', handlePayTRMessage);
    return () => {
      document.body.removeChild(script);
      window.removeEventListener('message', handlePayTRMessage);
    };
  }, [onMessage, onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-4xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          ✕
        </button>
        <div className="w-full h-[600px] relative mt-8">
          <iframe
            ref={iframeRef}
            id="paytriframe"
            src={`https://www.paytr.com/odeme/guvenli/${iframeToken}`}
            frameBorder="0"
            scrolling="no"
            style={{ width: '100%', minHeight: '100%' }}
            className="rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentFrame;