import axios, { AxiosError } from 'axios';
import { config } from '../config/env';

interface AuthResponse {
  token: string;
  user: {
    id: number;
    email: string;
    credits: number;
    createdAt: string;
  };
}

interface PaymentResponse {
  success: boolean;
  params: Record<string, string>;
  error?: string;
}

interface MusicGenerationResponse {
  audioUrl: string;
}

const api = axios.create({
  baseURL: `${config.API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

const handleAxiosError = (error: AxiosError): never => {
  if (error.response?.data && typeof error.response.data === 'object') {
    const errorData = error.response.data as { error?: string; message?: string };
    throw new Error(errorData.error || errorData.message || 'An unexpected error occurred');
  }
  throw new Error(error.message || 'Network error occurred');
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request configuration error:', error);
    return Promise.reject(new Error('Failed to configure request'));
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      return Promise.reject(handleAxiosError(error));
    }
    console.error('Non-Axios error:', error);
    return Promise.reject(new Error('An unexpected error occurred'));
  }
);

const apiService = {
  async analyzeMessage(message: string): Promise<string> {
    try {
      const response = await api.post('/analyze', { message });
      return response.data.analysis;
    } catch (error) {
      console.error('Message analysis error:', error);
      throw error;
    }
  },
  async generateMusic(formData: FormData): Promise<MusicGenerationResponse> {
    try {
      const response = await api.post<MusicGenerationResponse>('/music/generate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Music generation error:', error);
      throw error;
    }
  },

  async login(email: string, password: string): Promise<AuthResponse> {
    try {
      const response = await api.post<AuthResponse>('/auth/login', {
        email: email.trim(),
        password,
      });
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  async register(email: string, password: string): Promise<AuthResponse> {
    try {
      const response = await api.post<AuthResponse>('/auth/register', {
        email: email.trim(),
        password,
      });
      return response.data;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  },

  async getCredits(): Promise<{ credits: number }> {
    try {
      const response = await api.get<{ credits: number }>('/credits');
      return response.data;
    } catch (error) {
      console.error('Get credits error:', error);
      throw error;
    }
  },

  async processPayment(data: { 
    amount: number; 
    credits: number;
  }): Promise<PaymentResponse> {
    try {
      if (!data.amount || !data.credits) {
        throw new Error('Invalid payment data: amount and credits are required');
      }

      const response = await api.post<PaymentResponse>('/payment/create', data);
      
      if (!response.data?.success || !response.data?.params) {
        throw new Error('Invalid payment response: missing required data');
      }

      return response.data;
    } catch (error) {
      console.error('Payment creation error:', error);
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('Payment processing failed');
    }
  },

  async useCredit(): Promise<{ credits: number }> {
    try {
      const response = await api.post<{ credits: number }>('/credits/use');
      return response.data;
    } catch (error) {
      console.error('Use credit error:', error);
      throw error;
    }
  },
};

export default apiService;