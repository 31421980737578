import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { XCircle } from 'lucide-react';
import { toast } from 'react-hot-toast';

function PaymentFailure() {
  const navigate = useNavigate();

  useEffect(() => {
    toast.error('Ödeme işlemi başarısız oldu.');
    
    // Redirect to dashboard after 3 seconds
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 3000);

    // Cleanup timeout on unmount
    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  return (
    <div className="min-h-[calc(100vh-4rem)] flex items-center justify-center">
      <div className="text-center bg-white p-8 rounded-xl shadow-lg max-w-md w-full mx-4">
        <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
        <h1 className="text-2xl font-bold mb-2">Ödeme Başarısız</h1>
        <p className="text-gray-600 mb-4">
          Ödeme işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.
        </p>
        <p className="text-sm text-gray-500">
          Dashboard'a yönlendiriliyorsunuz...
        </p>
      </div>
    </div>
  );
}

export default PaymentFailure;