import React, { useState } from "react";
import { QrCode, Download, Loader, RefreshCcw, Sparkles } from "lucide-react";
import { toast } from "react-hot-toast";
import { useLanguage } from "../context/LanguageContext";
import { config } from "../config/env";
type SchedulerType =
  | "DDIM"
  | "DPMSolverMultistep"
  | "HeunDiscrete"
  | "K_EULER_ANCESTRAL"
  | "K_EULER"
  | "KLMS"
  | "PNDM"
  | "UniPCMultistep";

function QRCodeGenerator() {
  const [url, setUrl] = useState("");
  const [prompt, setPrompt] = useState("");
  const [conditioningScale, setConditioningScale] = useState(1);
  const [guidanceScale, setGuidanceScale] = useState(9);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [s3Key, setS3Key] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState("");
  const { t } = useLanguage();
  const [scheduler, setScheduler] = useState<SchedulerType>("DDIM");
  const schedulers: { value: SchedulerType; label: string }[] = [
    { value: "DDIM", label: "DDIM" },
    { value: "DPMSolverMultistep", label: "DPM-Solver" },
    { value: "HeunDiscrete", label: "Heun Discrete" },
    { value: "K_EULER_ANCESTRAL", label: "Euler Ancestral" },
    { value: "K_EULER", label: "Euler" },
    { value: "KLMS", label: "KLMS" },
    { value: "PNDM", label: "PNDM" },
    { value: "UniPCMultistep", label: "UniPC" },
  ];

  const handleGenerate = async () => {
    if (!url.trim()) {
      toast.error(t("qrGen.urlRequired"));
      return;
    }

    setIsGenerating(true);
    setProgress("");
    setImageUrl(null);
    setS3Key(null);

    try {
      const response = await fetch(`${config.API_URL}/api/qr/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          url,
          prompt,
          qr_conditioning_scale: conditioningScale,
          guidance_scale: guidanceScale,
          scheduler,
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        if (response.status === 402) {
          toast.error(t("qrGen.insufficientCredits"));
          return;
        }
        throw new Error(error.message || "Generation failed");
      }

      const reader = response.body?.getReader();
      if (!reader) throw new Error("No reader available");

      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");

        buffer = lines.pop() || "";

        for (const line of lines) {
          if (line.startsWith("data: ")) {
            try {
              const data = JSON.parse(line.slice(6));

              switch (data.type) {
                case "progress":
                  setProgress(data.data);
                  break;
                case "output":
                  setImageUrl(data.url);
                  setS3Key(data.s3Key);
                  toast.success(t("qrGen.success"));
                  break;
                case "error":
                  throw new Error(data.message);
              }
            } catch (e) {
              console.error("Event parsing error:", e);
            }
          }
        }
      }
    } catch (error) {
      console.error("QR generation error:", error);
      toast.error(t("qrGen.error"));
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownload = async () => {
    if (imageUrl) {
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = `qr-code-${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center justify-center gap-2">
            <QrCode className="w-6 h-6 text-indigo-600" />
            {t("qrGen.title")}
          </h2>
          <p className="mt-2 text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t("qrGen.description")}
          </p>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            {t("qrGen.urlLabel")}
          </label>
          <input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            placeholder="https://example.com"
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            {t("qrGen.styleLabel")}
          </label>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full h-32 p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-none bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            placeholder={t("qrGen.stylePlaceholder")}
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            QR Conditioning Scale
            <span className="ml-2 text-xs text-gray-500">
              ( QR kodunun netliğini kontrol eder)
            </span>
          </label>
          <input
            type="range"
            min="0.1"
            max="3"
            step="0.1"
            value={conditioningScale}
            onChange={(e) => setConditioningScale(parseFloat(e.target.value))}
            className="w-full"
          />
          <div className="flex justify-between text-sm text-gray-500">
            <span>0.1</span>
            <span>{conditioningScale}</span>
            <span>3.0</span>
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            Scheduler
            <span className="ml-2 text-xs text-gray-500">
              (Üretim sürecini kontrol eder)
            </span>
          </label>
          <select
            value={scheduler}
            onChange={(e) => setScheduler(e.target.value as SchedulerType)}
            className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          >
            {schedulers.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <p className="mt-1 text-xs text-gray-500">
          Farklı zamanlayıcılar kalite ve üretim hızı açısından farklı sonuçlar üretebilir.

          </p>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            Guidance Scale
            <span className="ml-2 text-xs text-gray-500">
              (Stile bağlılığını kontrol eder)
            </span>
          </label>
          <input
            type="range"
            min="0.1"
            max="30"
            step="0.1"
            value={guidanceScale}
            onChange={(e) => setGuidanceScale(parseFloat(e.target.value))}
            className="w-full"
          />
          <div className="flex justify-between text-sm text-gray-500">
            <span>0.1</span>
            <span>{guidanceScale}</span>
            <span>30.0</span>
          </div>
        </div>

        <button
          onClick={handleGenerate}
          disabled={isGenerating}
          className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-3 px-6 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 flex items-center justify-center"
        >
          {isGenerating ? (
            <>
              <Loader className="animate-spin mr-2" />
              {t("qrGen.generating")}
            </>
          ) : (
            <>
              <QrCode className="mr-2" />
              {t("qrGen.generate")}
            </>
          )}
        </button>

        {progress && (
          <div className="mt-4 text-center text-gray-600 dark:text-gray-300">
            {progress}
          </div>
        )}

        {imageUrl && (
          <div className="mt-6 space-y-4">
            <div className="relative rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700">
              <img
                src={imageUrl}
                alt="Generated QR Code"
                className="w-full h-auto"
                loading="lazy"
              />
              <div className="absolute top-4 right-4 space-x-2">
                <button
                  onClick={handleDownload}
                  className="p-2 bg-white/90 dark:bg-gray-800/90 rounded-full hover:bg-white dark:hover:bg-gray-800 transition-colors shadow-lg"
                  title={t("qrGen.download")}
                >
                  <Download className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                </button>
                <button
                  onClick={() => {
                    setImageUrl(null);
                    setS3Key(null);
                  }}
                  className="p-2 bg-white/90 dark:bg-gray-800/90 rounded-full hover:bg-white dark:hover:bg-gray-800 transition-colors shadow-lg"
                  title={t("qrGen.clear")}
                >
                  <RefreshCcw className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                </button>
              </div>
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
              {t("qrGen.savedToGallery")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default QRCodeGenerator;
