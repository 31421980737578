import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Sparkles, Image, BookOpen } from "lucide-react";
import { useAuth } from "../context/AuthContext";
import { useLanguage } from "../context/LanguageContext";
import ThemeToggle from "./ThemeToggle";
import LanguageSelector from "./LanguageSelector";

function Navbar() {
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();
  const { t } = useLanguage();

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white dark:bg-gray-900 shadow-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center space-x-2">
            <Sparkles className="w-8 h-8 text-indigo-600 dark:text-indigo-400" />
            <span className="font-bold text-xl text-gray-900 dark:text-white">AI Fun Hub</span>
          </Link>

          <div className="flex items-center space-x-4">
            <Link
              to="/blog"
              className={`px-4 py-2 rounded-lg font-medium ${
                location.pathname.startsWith("/blog")
                  ? "bg-indigo-600 text-white"
                  : "text-gray-700 dark:text-gray-200 hover:bg- gray-100 dark:hover:bg-gray-800"
              }`}
            >
              <BookOpen className="w-5 h-5 inline-block mr-1" />
              Blog
            </Link>

            {!isAuthenticated ? (
              <>
                <Link
                  to="/login"
                  className={`px-4 py-2 rounded-lg font-medium ${
                    location.pathname === "/login"
                      ? "bg-indigo-600 text-white"
                      : "text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800"
                  }`}
                >
                  {t('nav.login')}
                </Link>

              </>
            ) : (
              <>
                <Link
                  to="/dashboard"
                  className={`px-4 py-2 rounded-lg font-medium ${
                    location.pathname === "/dashboard"
                      ? "bg-indigo-600 text-white"
                      : "text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800"
                  }`}
                >
                  {t('nav.dashboard')}
                </Link>
                <Link
                  to="/gallery"
                  className={`px-4 py-2 rounded-lg font-medium ${
                    location.pathname === "/gallery"
                      ? "bg-indigo-600 text-white"
                      : "text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800"
                  }`}
                >
                  <Image className="w-5 h-5 inline-block mr-1" />
                  Gallery
                </Link>
                <button
                  onClick={logout}
                  className="px-4 py-2 rounded-lg font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  {t('nav.logout')}
                </button>
              </>
            )}
            <Link
              to="/about"
              className={`px-4 py-2 rounded-lg font-medium ${
                location.pathname === "/about"
                  ? "bg-indigo-600 text-white"
                  : "text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800"
              }`}
            >
              {t('nav.about')}
            </Link>
            <ThemeToggle />
            <LanguageSelector />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;