import { Link } from "react-scroll";
import {
  Info,
  Phone,
  Shield,
  FileText,
  ShoppingCart,
  RefreshCw,
  ChevronRight,
  Mail,
  MapPin,
  Clock,
} from "lucide-react";

function About() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 via-white to-indigo-50">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-20">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1639322537228-f710d846310a')] bg-cover bg-center opacity-10"></div>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <h1 className="text-5xl font-extrabold mb-4 text-center">Hakkımızda</h1>
          <p className="text-xl text-center max-w-2xl mx-auto text-indigo-100">
            AI Fun Hub olarak, yapay zekanın gücünü herkesin kullanımına sunarak hayatınızı kolaylaştırıyoruz.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Quick Links */}
        <div className="grid md:grid-cols-3 gap-6 mb-16">
          <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
            <Mail className="w-8 h-8 text-indigo-600 mb-4" />
            <h3 className="text-lg font-semibold mb-2">E-posta</h3>
            <p className="text-gray-600">info@aifunhub.com</p>
          </div>
          <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
            <MapPin className="w-8 h-8 text-indigo-600 mb-4" />
            <h3 className="text-lg font-semibold mb-2">Adres</h3>
            <p className="text-gray-600">Kolektif House Levent, İstanbul</p>
          </div>
          <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
            <Clock className="w-8 h-8 text-indigo-600 mb-4" />
            <h3 className="text-lg font-semibold mb-2">Çalışma Saatleri</h3>
            <p className="text-gray-600">Hafta içi: 09:00 - 18:00</p>
          </div>
        </div>

        {/* Navigation Cards */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {[
            { icon: <Info />, title: "Hakkımızda", id: "hakkimizda" },
            { icon: <Phone />, title: "İletişim", id: "iletisim" },
            { icon: <Shield />, title: "Kullanım Koşulları", id: "kullanim" },
            { icon: <FileText />, title: "Gizlilik", id: "gizlilik" },
            { icon: <ShoppingCart />, title: "Mesafeli Satış", id: "mesafeli-satis" },
            { icon: <RefreshCw />, title: "İade Şartları", id: "iade" },
          ].map((item) => (
            <Link
              key={item.id}
              to={item.id}
              smooth
              duration={500}
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all cursor-pointer group"
            >
              <div className="p-6 flex items-center space-x-4">
                <div className="bg-indigo-50 rounded-lg p-3 text-indigo-600 group-hover:bg-indigo-600 group-hover:text-white transition-colors">
                  {item.icon}
                </div>
                <span className="font-medium text-gray-900 group-hover:text-indigo-600 transition-colors">
                  {item.title}
                </span>
                <ChevronRight className="ml-auto text-gray-400 group-hover:text-indigo-600 transition-colors" />
              </div>
            </Link>
          ))}
        </div>

        {/* Content Sections */}
        <div className="space-y-16">
          <section id="hakkimizda" className="scroll-mt-16">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-900">Hakkımızda</h2>
              <div className="prose prose-indigo max-w-none">
                <p className="text-gray-700 leading-relaxed">
                  AI Fun Hub olarak, yapay zekanın gücünü herkesin kullanımına sunmayı amaçlıyoruz. 
                  Çeşitli AI araçlarımızla hayatınızı kolaylaştıracak ve eğlenceli hale getirecek çözümler sunuyoruz.
                </p>
              </div>
            </div>
          </section>

          <section id="iletisim" className="scroll-mt-16">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-900">İletişim</h2>
              <div className="prose prose-indigo max-w-none">
                <p className="text-gray-700 leading-relaxed">
                  Bize ulaşmak için{" "}
                  <a href="mailto:info@aifunhub.com" className="text-indigo-600 hover:underline">
                    info@aifunhub.com
                  </a>{" "}
                  adresine e-posta gönderebilirsiniz.
                </p>
                <div className="mt-4 space-y-2">
                  <p><span className="font-semibold">Adres:</span> Kolektif House Levent. Esentepe Mah. Talatpaşa Caddesi No: 5 Levent, İstanbul</p>
                  <p><span className="font-semibold">Tel:</span> 0 (212) 909 - 23 - 10</p>
                </div>
              </div>
            </div>
          </section>

          <section id="kullanim" className="scroll-mt-16">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-900">Kullanım Koşulları</h2>
              <div className="prose prose-indigo max-w-none">
                <p className="text-gray-700 leading-relaxed">
                  Hizmetlerimizi kullanırken belirli şart ve koşullara uymanız gerekmektedir.
                </p>
              </div>
            </div>
          </section>

          <section id="gizlilik" className="scroll-mt-16">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-900">Gizlilik</h2>
              <div className="prose prose-indigo max-w-none">
                <p className="text-gray-700 leading-relaxed">
                  Güvenliğiniz bizim için önemli. Bu sebeple bizimle paylaşacağınız kişisel verileriz 
                  hassasiyetle koruyoruz. 6698 Kişisel Verilerin Korunması Kanunu çerçevesinde kişisel 
                  verilerin işlenmesinde temel hak ve özgürlükleri korumaktayız.
                </p>
              </div>
            </div>
          </section>

          <section id="mesafeli-satis" className="scroll-mt-16">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-900">Mesafeli Satış Sözleşmesi</h2>
              <div className="prose prose-indigo max-w-none">
                <p className="text-gray-700 leading-relaxed">
                  Ürün ve hizmetlerimizle ilgili mesafeli satış sözleşmesi koşullarını buradan inceleyebilirsiniz.
                </p>
              </div>
            </div>
          </section>

          <section id="iade" className="scroll-mt-16">
            <div className="bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-900">İade Şartları</h2>
              <div className="prose prose-indigo max-w-none">
                <p className="text-gray-700 leading-relaxed">
                  Elektronik ortamda Müşteri'ye derhal teslim edilen ve derhal yararlanma sağlanan bir 
                  hizmet/yazılım olduğu için iadesi mümkün değildir. Müşteri, üye olduğu an derhal 
                  yazılımdan yararlanmaya başladığında hizmet ifa edilmeye başlanmış olacağından, 
                  ALICI' nın tüketici olması halinde dahi cayma hakkının kullanımı söz konusu olamaz.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default About;