import React, { useState } from 'react';
import { Download, Loader, Lock, Sparkles } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useLanguage } from '../context/LanguageContext';
import { config } from '../config/env';

interface SVGCreatorProps {
  isPremium?: boolean;
}

type StyleType = 'any' | 'engraving' | 'line_art' | 'line_circuit' | 'linocut';
type SizeType = '1024x1024' | '1365x1024' | '1024x1365' | '1536x1024' | '1024x1536' | 
                '1820x1024' | '1024x1820' | '1024x2048' | '2048x1024' | '1434x1024' | 
                '1024x1434' | '1024x1280' | '1280x1024' | '1024x1707' | '1707x1024';

function SVGCreator({ isPremium = false }: SVGCreatorProps) {
  const [prompt, setPrompt] = useState('');
  const [style, setStyle] = useState<StyleType>('any');
  const [size, setSize] = useState<SizeType>('1024x1024');
  const [svgUrl, setSvgUrl] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { t } = useLanguage();

  const styles: { value: StyleType; label: string }[] = [
    { value: 'any', label: 'Any Style' },
    { value: 'engraving', label: 'Engraving' },
    { value: 'line_art', label: 'Line Art' },
    { value: 'line_circuit', label: 'Line Circuit' },
    { value: 'linocut', label: 'Linocut' }
  ];

  const sizes: { value: SizeType; label: string }[] = [
    { value: '1024x1024', label: '1024x1024 (Square)' },
    { value: '1365x1024', label: '1365x1024 (Landscape)' },
    { value: '1024x1365', label: '1024x1365 (Portrait)' },
    { value: '1536x1024', label: '1536x1024 (Wide)' },
    { value: '1024x1536', label: '1024x1536 (Tall)' },
    { value: '1820x1024', label: '1820x1024 (Ultra Wide)' },
    { value: '1024x1820', label: '1024x1820 (Ultra Tall)' },
    { value: '2048x1024', label: '2048x1024 (Cinema)' },
    { value: '1024x2048', label: '1024x2048 (Mobile)' }
  ];

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      toast.error('Please enter a prompt');
      return;
    }

    setIsGenerating(true);
    setSvgUrl(null);

    try {
      const response = await fetch(`${config.API_URL}/api/svg/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          prompt,
          style: isPremium ? style : 'any',
          size: isPremium ? size : '1024x1024'
        }),
      });

      if (!response.ok) {
        if (response.status === 402) {
          toast.error('Insufficient credits');
          return;
        }
        throw new Error('Generation failed');
      }

      const data = await response.json();
      setSvgUrl(data.url);
      toast.success('SVG generated successfully!');
    } catch (error) {
      console.error('SVG generation error:', error);
      toast.error('Failed to generate SVG');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownload = async () => {
    if (!svgUrl) return;

    try {
      const response = await fetch(svgUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `generated-svg-${Date.now()}.svg`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Failed to download SVG');
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center justify-center gap-2">
            <Sparkles className="w-6 h-6 text-indigo-600" />
            SVG Creator
          </h2>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            Generate beautiful SVG illustrations from text descriptions
          </p>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            Prompt
          </label>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full h-32 p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-none bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            placeholder="Describe the SVG you want to generate..."
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
              Style
              {!isPremium && (
                <span className="ml-2 text-xs text-gray-500 flex items-center">
                  <Lock className="w-3 h-3 mr-1" />
                  Premium only
                </span>
              )}
            </label>
            <select
              value={style}
              onChange={(e) => setStyle(e.target.value as StyleType)}
              disabled={!isPremium}
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => !isPremium && setShowUpgradeModal(true)}
            >
              {styles.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
              Size
              {!isPremium && (
                <span className="ml-2 text-xs text-gray-500 flex items-center">
                  <Lock className="w-3 h-3 mr-1" />
                  Premium only
                </span>
              )}
            </label>
            <select
              value={size}
              onChange={(e) => setSize(e.target.value as SizeType)}
              disabled={!isPremium}
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => !isPremium && setShowUpgradeModal(true)}
            >
              {sizes.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          onClick={handleGenerate}
          disabled={isGenerating || !prompt.trim()}
          className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-3 px-6 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 flex items-center justify-center"
        >
          {isGenerating ? (
            <>
              <Loader className="animate-spin mr-2" />
              Generating...
            </>
          ) : (
            'Generate SVG'
          )}
        </button>

        {svgUrl && (
          <div className="mt-6 space-y-4">
            <div className="relative rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700 p-4">
              <img
                src={svgUrl}
                alt="Generated SVG"
                className="w-full h-auto"
                loading="lazy"
              />
              <div className="absolute top-4 right-4">
                <button
                  onClick={handleDownload}
                  className="p-2 bg-white/90 dark:bg-gray-800/90 rounded-full hover:bg-white dark:hover:bg-gray-800 transition-colors shadow-lg"
                  title="Download SVG"
                >
                  <Download className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {showUpgradeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
              Upgrade to Premium
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Unlock advanced features including custom styles and sizes by upgrading to our Premium plan.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowUpgradeModal(false)}
                className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShowUpgradeModal(false);
                  // Add navigation to subscription page or show subscription modal
                }}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Upgrade Now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SVGCreator;