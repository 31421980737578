import React, { useState, useRef, useEffect } from "react";
import { Send, Loader } from "lucide-react";
import { toast } from "react-hot-toast";
import { config } from "../config/env";
import SubscriptionPlans from "./SubscriptionPlans";
import PaymentFrame from "./PaymentFrame";

interface Message {
  role: "user" | "assistant";
  content: string;
}

interface Subscription {
  status: "none" | "basic" | "premium" | "enterprise";
  endDate: string | null;
}

function ChatWithLlama() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = async () => {
    try {
      const response = await fetch(`${config.API_URL}/api/subscription`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch subscription");
      const data = await response.json();
      setSubscription(data);
    } catch (error) {
      console.error("Error fetching subscription:", error);
      toast.error("Failed to fetch subscription status");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    if (!subscription || subscription.status === "none" as any) {
      toast.error("Please subscribe to continue using the chat.");
      setShowSubscriptionModal(true);
      return;
    }

    const userMessage = input.trim();
    setInput("");
    setMessages((prev) => [...prev, { role: "user", content: userMessage }]);
    setIsLoading(true);

    try {
      const response = await fetch(`${config.API_URL}/api/analyze`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          message: userMessage,
          systemPrompt:
            "Siz yardımsever ve arkadaş canlısı bir yapay zeka asistanı olan LLama'sınız. Kullanıcının mesajlarına bilgilendirici ve doğru olurken doğal, konuşkan bir şekilde yanıt verin. Yanıtları kısa ama eksiksiz tutun. Kullanıcıya cevapları yazdığı dilde verin.",
        }),
      });

      if (!response.ok) throw new Error("Failed to get response");

      const reader = response.body?.getReader();
      if (!reader) throw new Error("No reader available");

      let assistantMessage = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const text = new TextDecoder().decode(value);
        const lines = text.split("\n");

        for (const line of lines) {
          if (line.startsWith("data: ")) {
            const data = line.slice(6);
            if (data === "[DONE]") break;
            assistantMessage += data;
            setMessages((prev) => {
              const newMessages = [...prev];
              if (newMessages[newMessages.length - 1]?.role === "assistant") {
                newMessages[newMessages.length - 1].content = assistantMessage;
              } else {
                newMessages.push({
                  role: "assistant",
                  content: assistantMessage,
                });
              }
              return newMessages;
            });
          }
        }
      }
    } catch (error) {
      console.error("Chat error:", error);
      toast.error("Failed to get response");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = async (plan: any) => {
    try {
      const response = await fetch(`${config.API_URL}/api/payment/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          type: "subscription",
          planType: plan.type,
          amount: plan.price,
        }),
      });

      if (!response.ok) throw new Error("Failed to create subscription");

      const data = await response.json();
      if (data.success && data.params?.iframe_token) {
        setPaymentToken(data.params.iframe_token);
        setShowSubscriptionModal(false);
      } else {
        throw new Error("Invalid payment response");
      }
    } catch (error) {
      console.error("Subscription error:", error);
      toast.error("Failed to process subscription");
    }
  };

  const handlePaymentMessage = (status: "success" | "failed") => {
    if (status === "success") {
      toast.success("Payment successful! Your subscription is now active.");
      fetchSubscription();
    } else {
      toast.error("Payment failed. Please try again.");
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4 max-w-4xl mx-auto">
      {subscription?.status === "none" ? (
        <div className="p-6">
          <h2 className="text-2xl font-bold text-center mb-8">
            Choose Your Subscription Plan
          </h2>
          <SubscriptionPlans onSelectPlan={handleSubscribe} />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600 dark:text-gray-300">
                Subscription:
              </span>
              <span className="font-semibold text-indigo-600 dark:text-indigo-400 capitalize">
                {subscription?.status || "None"}
              </span>
            </div>
            {subscription?.endDate && (
              <span className="text-sm text-gray-600 dark:text-gray-300">
                Expires: {new Date(subscription.endDate).toLocaleDateString()}
              </span>
            )}
          </div>

          <div className="h-[60vh] flex flex-col">
            <div className="flex-1 overflow-y-auto mb-4 space-y-4 p-4">
              {messages.length === 0 ? (
                <div className="text-center text-gray-500 dark:text-gray-400 mt-8">
                  Start a conversation with Llama! Ask me anything...
                </div>
              ) : (
                messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      message.role === "user" ? "justify-end" : "justify-start"
                    }`}
                  >
                    <div
                      className={`max-w-[80%] rounded-lg px-4 py-2 ${
                        message.role === "user"
                          ? "bg-indigo-600 text-white"
                          : "bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                      }`}
                    >
                      <p className="whitespace-pre-wrap">{message.content}</p>
                    </div>
                  </div>
                ))
              )}
              <div ref={messagesEndRef} />
            </div>

            <form
              onSubmit={handleSubmit}
              className="flex gap-2 p-2 border-t dark:border-gray-700"
            >
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-gray-100"
                disabled={
                  isLoading || !subscription || subscription.status === "none" as any
                }
              />
              <button
                type="submit"
                disabled={
                  isLoading ||
                  !input.trim() ||
                  !subscription ||
                  subscription.status === "none" as any
                }
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <Loader className="w-5 h-5 animate-spin" />
                ) : (
                  <Send className="w-5 h-5" />
                )}
              </button>
            </form>
          </div>
        </>
      )}
      {paymentToken && (
        <PaymentFrame
          iframeToken={paymentToken}
          onClose={() => setPaymentToken(null)}
          onMessage={handlePaymentMessage}
        />
      )}
      {showSubscriptionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-4xl w-full mx-4">
            <h2 className="text-2xl font-bold text-center mb-8">
              Choose Your Subscription Plan
            </h2>
            <SubscriptionPlans onSelectPlan={handleSubscribe} />
            <button
              onClick={() => setShowSubscriptionModal(false)}
              className="mt-6 w-full py-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatWithLlama;
